import * as React from "react"
import NavMenuButton from "./NavMenuButton"

interface navMenuContainerProps {
  navMenus: {
    id: string,
    label: string,
    url: string
    subMenus?: {
      id: string,
      label: string,
      url: string
    }[]
  }[]
}

export default function navMenuContainer({ navMenus: navMenus }: navMenuContainerProps) {
  const [activeTab, setActiveTab] = React.useState<string>("Tab1")

  const navMenuItems = navMenus.map((navMenu, index: number) => (
    <NavMenuButton
      id={navMenu.id}
      key={index}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      url={navMenu.url}
      tabLabel={navMenu.label}
      subMenus={navMenu.subMenus}
    />
  ))

  return <div className="navMenuContainer">{navMenuItems}</div>
}
