import * as React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas, faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MobileMegaMenu from './MobileMegaMenu';

library.add(fas, faBars, faXmark);

interface HamburgerMenuProps {
  menus: {
    id: string;
    label: string;
    url: string;
    subMenus?: {
      id: string;
      label: string;
      url: string;
    }[];
  }[];
}

function HamburgerMenuButton(props: HamburgerMenuProps) {
  const { menus } = props;

  const [showMenu, setShowMenu] = React.useState(false);

  const removeClass = () => {
    if (typeof window === 'undefined' || !window.document) {
      return;
    }
    const el: HTMLElement | null = document.getElementById('mobileMegaMenu');
    const body: HTMLBodyElement | null = document.querySelector('body');
    if (!el?.classList.contains('show')) {
      body!.style.overflowY = 'none';
    } else {
      body!.style.overflowY = 'scroll';
    }
  };

  const timedRemoveClass = () => {
    setTimeout(removeClass, 250);
    // This should be the same value as the animation duration for hiding the menu in layout.css
  };

  return (
    <>
      <button
        className="hamburgerMenuButton"
        type="button"
        aria-haspopup="menu"
        aria-expanded={showMenu ? 'true' : 'false'}
        onClick={() => {
          setShowMenu((previous: boolean) => !previous);
          removeClass();
        }}
      >
        {showMenu ? (
          <FontAwesomeIcon icon={['fas', 'xmark']} />
        ) : (
          <FontAwesomeIcon icon={['fas', 'bars']} />
        )}
      </button>
      <MobileMegaMenu menus={menus} showMenu={showMenu} onClick={setShowMenu} />
      {removeClass()}
    </>
  );
}

export default HamburgerMenuButton;
