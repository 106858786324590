import * as React from 'react';

interface MobileMegaMenuProps {
  menus?: {
    label: string;
    subMenus?: {
      id: string;
      label: string;
      url: string;
    }[];
  }[];
  showMenu: boolean;
  onClick(): void;
}

function MobileMegaMenu(props: MobileMegaMenuProps) {
  const { menus, showMenu, onClick } = props;
  function HandleLinkClick(event) {
    onClick(event.target.value);
  }
  return (
    <div
      id="mobileMegaMenu"
      className={`mobileMegaMenu ${showMenu ? 'show' : 'hide'}`}
    >
      {menus?.map((menu, index: number) => (
        <ul key={index}>
          {menu.label}
          {menu.subMenus?.map((submenu, i: number) => (
            <li key={i}>
              <a href={submenu.url} onClick={HandleLinkClick}>
                {submenu.label}
              </a>
            </li>
          ))}
        </ul>
      ))}
    </div>
  );
}

export default MobileMegaMenu;
