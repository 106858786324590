import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { withPrefix } from "gatsby";
import NavMenuContainer from "./NavMenuContainer";
import HamburgerMenuButton from "./HamburgerMenuButton";

// TODO : Derive this from Google Docs?
const menuItems = [
  {
    id: "Tab1",
    label: "Passport for Job Seekers",
    url: "/",
    subMenus: [
      {
        id: "item1",
        label: "Learn More",
        url: withPrefix("/passport-page"),
      },
      {
        id: "item2",
        label: "Sign Up",
        url: "https://app.purepost.co/users/sign_up",
      },
      {
        id: "item3",
        label: "Log in to Passport",
        url: "https://app.purepost.co/users/sign_in",
      },
    ],
  },
  {
    id: "Tab2",
    label: "Compass for ERGs, VSOs, and Higher Ed",
    url: "/",
    subMenus: [
      {
        id: "item1",
        label: "Learn More",
        url: withPrefix("/compass-page"),
      },
      {
        id: "item2",
        label: "Request a Demo",
        url: "mailto:info@purepost.co",
      },
      {
        id: "item3",
        label: "Log in to Compass",
        url: "https://app.purepost.co/partners/sign_in",
      },
    ],
  },
  {
    id: "Tab3",
    label: "Sonar for Employers",
    url: "/",
    subMenus: [
      {
        id: "item1",
        label: "Learn More",
        url: withPrefix("/sonar-page"),
      },
      {
        id: "item2",
        label: "Request a Demo",
        url: "mailto:info@purepost.co",
      },
      {
        id: "item3",
        label: "Log in to Sonar",
        url: "https://app.purepost.co/partners/sign_in",
      },
    ],
  },
  {
    id: "Tab4",
    label: "More",
    url: "/",
    subMenus: [
      {
        id: "item1",
        label: "Blog",
        url: withPrefix("/blog-index"),
      },
      {
        id: "item3",
        label: "About Us",
        url: withPrefix("/about-purepost"),
      },
      {
        id: "item2",
        label: "News",
        url: withPrefix("/news"),
      },
      // We'll add these in when content ready
    ],
  },
];

function Header() {
  return (
    <header className="navHeader">
      <div className="logoContainer">
        <a href={withPrefix("/")}>
          <StaticImage
            className="logo"
            imgClassName="logoImg"
            objectPosition="0 0"
            src="../../images/purepost-logo-redblack.png"
            layout="fixed"
            loading="eager"
            width={185}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Purepost logo"
          />
        </a>
      </div>
      <NavMenuContainer navMenus={menuItems} />
      <HamburgerMenuButton menus={menuItems} />
    </header>
  );
}

export default Header;
