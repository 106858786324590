import * as React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from './NavDropdown';
import passport_logo from '../../images/product_logos/rgb_passport_logomark.png';
import compass_logo from '../../images/product_logos/rgb_compass_logomark.png';
import sonar_logo from '../../images/product_logos/rgb_sonar_logomark.png';

library.add(fas, faCaretDown);

interface navTabProps {
  id: string;
  tabLabel: string;
  url: string;
  activeTab: string;
  setActiveTab: Function;
  subMenus?: {
    id: string;
    label: string;
    url: string;
  }[];
}

const NavMenuButton: React.FC<navTabProps> = ({
  tabLabel,
  subMenus,
}: navTabProps) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const [showDropdown, setShowDropdown] = React.useState(false);

  React.useEffect(() => {
    const handler = (e: MouseEvent | TouchEvent) => {
      if (
        showDropdown &&
        ref.current &&
        !ref.current.contains(e.target as HTMLElement)
      ) {
        setShowDropdown(false);
      }
    };
    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, [showDropdown]);

  const productLogo = () => {
    if (tabLabel.toLowerCase().includes('compass')) {
      return { title: 'Purepost Compass', logo: compass_logo };
    }
    if (tabLabel.toLowerCase().includes('sonar')) {
      return { title: 'Purepost Sonar', logo: sonar_logo };
    }
    if (tabLabel.toLowerCase().includes('passport')) {
      return { title: 'Purepost Passport', logo: passport_logo };
    }
    return { title: null, logo: null };
  };

  return (
    <div className='navMenuButton' ref={ref}>
      <button
        type='button'
        aria-haspopup='menu'
        aria-expanded={showDropdown ? 'true' : 'false'}
        onClick={() => setShowDropdown((previous: boolean) => !previous)}
      >
        {productLogo().logo && <img src={productLogo().logo} alt='' />}
        {tabLabel}
      </button>
      <Dropdown
        product={tabLabel}
        subMenus={subMenus}
        showDropdown={showDropdown}
        onClick={setShowDropdown}
      />
    </div>
  );
};

export default NavMenuButton;
