import React from 'react';
import { graphql, useStaticQuery, withPrefix } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Section from "./Section";

export default function Footer() {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      footer: googleDocs(name: { eq: "Footer" }) {
        childMdx {
          body
        }
      }
    }
  `);

  return (
    <div className="footer-container">
      <div className="dol-vets-blurb">
        <StaticImage src="../images/dol_vets.png" layout="fixed" width={72}></StaticImage>
        <p>Purepost&#174; is a proud partner of the US Department of Labor VETS program.</p>
      </div>
      <MDXRenderer>{data.footer.childMdx.body}</MDXRenderer>
      <p>
        <a href={withPrefix('/blog-index')}>Blog</a>
      </p>
      <p className="font-small copyright-statement">&#169; 2023 Purepost, Inc.</p>
      {/* Need to add links for News and About when ready */}
    </div>
  );
}
