import * as React from 'react';
import passport_logo from '../../images/product_logos/passport_logomark.png';
import compass_logo from '../../images/product_logos/compass_logomark.png';
import sonar_logo from '../../images/product_logos/sonar_logomark.png';

interface DropdownProps {
  product: string;
  subMenus?: {
    id: string;
    label: string;
    url: string;
  }[];
  showDropdown: boolean;
  onClick(): void;
}

function Dropdown(props: DropdownProps) {
  function HandleLinkClick(event) {
    props.onClick(event.target.value);
  }
  const productLogo = () => {
    if (props.product.toLowerCase().includes('compass')) {
      return { title: 'Purepost Compass®', logo: compass_logo };
    }
    if (props.product.toLowerCase().includes('sonar')) {
      return { title: 'Purepost Sonar™', logo: sonar_logo };
    }
    if (props.product.toLowerCase().includes('passport')) {
      return { title: 'Purepost Passport™', logo: passport_logo };
    }
    return { title: null, logo: null}
  };

  return (
    <div className={`navDropdownContainer ${props.showDropdown ? 'show' : ''}`}>
      { productLogo().logo && <img className="navDropdownProductLogo" src={productLogo().logo} alt="" /> }
      <ul className="navDropdownList">
        {productLogo().title}
        {props?.subMenus?.map((submenu, index: number) => (
          <li key={index} className="menuItem">
            <a href={submenu.url} onClick={HandleLinkClick}>
              {submenu.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Dropdown;
