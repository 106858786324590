import * as React from 'react';

interface CtaButtonProps {
  href?: string;
}
const defaultProps = {
  href: 'https://app.purepost.co/users/sign_up',
};
export default function CtaButton(props: CtaButtonProps) {
  return (
    <a href={props.href}>
      <button type="button" className="cta" {...props} />
    </a>
  );
}
CtaButton.defaultProps = defaultProps;
