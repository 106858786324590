import * as React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { graphql, useStaticQuery } from "gatsby";

export default function Message(props) {
  const { location } = { ...props };
  const data = useStaticQuery(graphql`
    query MessageQuery {
      allGoogleDocs(filter: { path: { glob: "*/messages/*/*" } }) {
        edges {
          node {
            id
            name
            path
            childMdx {
              body
            }
          }
        }
      }
    }
  `);
  return (
    <>
      {data.allGoogleDocs.edges.map((edge) => {
        if (edge.node.path.includes(location)) {
          return (
            <div className="message-container">
              <MDXRenderer>{edge.node.childMdx.body}</MDXRenderer>
            </div>
          );
        }
      })}
    </>
  );
}
