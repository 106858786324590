/* eslint-disable react/prop-types */
import React from "react";
import { MDXProvider } from "@mdx-js/react";
import Header from "../components/headerNav/Header";
import Footer from "../components/Footer";
import CtaButton from "../components/CtaButton";
import Section from "../components/Section";
import Metrics from "../components/Metrics";
import Products from "../components/Products";
import Message from "../components/Message";

const layoutComponents = {
  CtaButton: (props) => <CtaButton {...props} />,
  Section: (props) => <Section {...props} />,
  Metrics: (props) => <Metrics {...props} />,
  Products: (props) => <Products {...props} />,
};

function Layout({ children }) {
  return (
    <div className="wrapper">
      {children.props.children.key === "/" && <Message location="home" />}
      {children.props.children.key === "/passport-page" && (
        <Message location="passport" />
      )}
      <Header />
      <div className="body-content">
        <MDXProvider components={layoutComponents}>{children}</MDXProvider>
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
