import * as React from 'react';

const Alignment = {
  left: 'left',
  center: 'center',
  right: 'right',
} as const;

type Alignment = typeof Alignment[keyof typeof Alignment];

interface ContentSectionProps {
  filled?: boolean;
  align?: Alignment;
  children?: React.ReactNode;
  id?: string | null;
  className?: string | null;
}

const defaultProps = {
  filled: false,
  align: 'left',
  children: null,
  id: null,
  className: null,
};

export function Section(props: ContentSectionProps & typeof defaultProps) {
  const {
    filled, align, children, id, className,
  } = props;
  return (
    <section
      className={`contentSection${filled ? ' filled' : ''}${
        ` ${align}` || ' left'
      }${` ${className}` || ''}`}
      id={id}
    >
      {children}
    </section>
  );
}

Section.defaultProps = defaultProps;

export default Section;
